import * as React from "react"
import Seo from "../components/seo"
import Info from "../components/info";
import Layout from "../components/layout";
import HeroHeader from "../components/HeroHeader";
import SubheaderLeft from "../components/SubheaderLeft";
import Pricelist from "../components/pricelist";
import Contact from "../components/Contact";
import Intro from "../components/Intro";
import { StaticImage } from "gatsby-plugin-image"



const LandingPage = () => {

    let subheader = {
        subheader: "Lyžiarska sezóna sa začína u nás",
        header: "Ponúkame požičanie:",
        text: [ <ul className="list-disc pl-4 pt-6">
                    <li>lyžiarskej výstroje</li>
                    <li>snowboardovej výstroje</li>
                    <li>bežkárskej výstroje</li>
                    <li>bigfoot</li>
                </ul>,
                <h5 className="pt-6 font-medium text-lg"></h5>,
                <ul className="list-disc pl-4 pt-6">
                    <li>lyžiarska obuv a prilby - pripravené a 100% hygienicky čisté</li>
                    <li>všetky lyže majú 100% pripravené hrany a sklznice</li>
                </ul>,
        ],
    }


    return (
        <div>
            <Layout>
            <HeroHeader title="Sezónne">
                    <StaticImage placeholder="blurred" className="h-[400px]" objectFit="cover" objectPosition="50% 50%" height="400"  imgClassName="object-cover" src="../../static/images/headers/hero-header1.png" alt="skiers" />
                </HeroHeader>
                <SubheaderLeft data={subheader}>
                    <div className="hidden md:block"><StaticImage placeholder="blurred" imgClassName="" src="../../static/images/subheaders/subheader02.png" alt="skiers" /></div>
                    <div className="md:hidden block"><StaticImage placeholder="blurred"  imgClassName="" src="../../static/images/subheaders/subheader02-small.png" alt="skiers" /></div>
                </SubheaderLeft>
                <div className="mx-auto text-2xl text-center py-5 lg:px-40 font-medium">
                    Dátum vrátenia do 31.3. bez doplatku.<br />
                    Dátum vrátenia do 30.4. - vrátenie možné iba 28, 29, 30 apríla od 16:00-19:00  doplatok 5 €.<br />
                    Dátum vrátenia do 31.5. - vrátenie možné iba 29, 30, 31 mája od 16:00-19:00  doplatok 10 €.<br />
                </div>
                <Pricelist tags="sezonne" />
                <Contact></Contact>
            </Layout>
        </div>
    )
}

export const Head = () => <Seo title="Home" />

export default LandingPage